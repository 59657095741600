import React, { Fragment, useEffect, useContext } from "react";
import { graphql } from "gatsby";
import SEO from "../components/layout/seo";
// import { Footer } from "../components/layout/footer";
import { NavbarLanding } from "../components/layout/navbarLanding";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";
import submit from "../images/newsletter-submit-icon.svg";
import BackgroundImg from '../images/background-element-content.svg';
import { domain } from "../utils";
import { FooterLanding } from "../components/layout/footerLanding";
import '../functions.js';
import classNames from "classnames";


const LandingPage = ({ data }) => {
  const { landingpage, homepages } = data.strapi;
  const {
    language,
    meta,
    intro,
    overviewSection,
    subscription,
    comments,
  } = landingpage;

  const dispatch = useContext(GlobalDispatchContext);
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language });
  });

  
  return (
    <Fragment>
      <NavbarLanding language={language}/>
      <SEO title={meta.title} description={meta.description}/>
      <div className="landing-page">
        {/* {pageContent && pageContent[0] && ( */}
          <>
            <div className="col-12 col-lg-10 offset-lg-1">
              <div className="container-fluid pt-lg-4">
                <div className="row d-flex align-items-center">
                  <div className="col-12 col-lg-6 offset-lg-1 mt-3 mt-lg-0">
                    <h2 className="text-left">{intro.title}</h2>
                      <ul className="px-2 mt-2">
                        {intro.description.split('- ').map((listItem, index) => {
                          const arrayLength = intro.description.split('- ').length;
                          
                          return index > 0 && (
                            <li className={classNames('pl-1', {"border-0": (index + 1) === arrayLength})}>{listItem}</li>
                          )
                        })}
                      </ul>
                    <div>
                      <form 
                        className="emailoctopus-form form-horizontal bound" 
                        action="https://emailoctopus.com/lists/b6e22eed-3f84-11eb-a3d0-06b4694bee2a/members/embedded/1.3s/add" 
                        method="post" 
                        data-message-success="Thanks for subscribing!" 
                        data-message-missing-email-address="Your email address is required." 
                        data-message-invalid-email-address="Your email address looks incorrect, please try again." 
                        data-message-bot-submission-error="This doesn't look like a human submission." 
                        data-message-consent-required="Please check the checkbox to indicate your consent." 
                        data-message-invalid-parameters-error="This form has missing or invalid fields." 
                        data-message-unknown-error="Sorry, an unknown error has occurred. Please try again later." 
                        data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6" 
                        data-widget-id="1"
                      >
                        <div className="emailoctopus-form-row form-group d-flex align-items-center justify-content-center mb-0 mt-3 text-left">
                          <input 
                            className="header-input input-lg bg-light border-0 form-control px-2 px-lg-3" 
                            id="field_0" 
                            name="field_0" 
                            type="email" 
                            placeholder="Bitte gib deine Mail Adresse ein" 
                            required={true}
                          />
                          <div className="emailoctopus-form-row-subscribe">
                            <input type="hidden" name="successRedirectUrl"/>
                            <button className="text-nowrap mb-0 ml-1 bg-danger submit-button py-2 px-3 border-0 header-input" type="submit">
                              <img className="mr-md-2 text-white" src={submit} alt="subscribe"/>
                              {language === 'en' ? (
                                <span className="d-none d-md-inline">
                                  Sign up
                                </span>
                              ) : (
                                <span className="d-none d-md-inline">
                                  Als Testuser eintragen
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="g-recaptcha d-none" data-theme="light" data-size="invisible" data-type="image" data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6">
                          <div className="grecaptcha-badge" data-style="none">
                            <div className="grecaptcha-logo">
                              <iframe 
                                title="grecaptcha-logo"
                                src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6&amp;co=aHR0cHM6Ly93d3cubWluYWJvdC5haTo0NDM.&amp;hl=de&amp;type=image&amp;v=-nejAZ5my6jV0Fbx9re8ChMK&amp;theme=light&amp;size=invisible&amp;cb=r4s9el81lfz6" 
                                width="256" 
                                height="60" 
                                role="presentation" 
                                name="a-vs0smw9uah8" 
                                scrolling="no" 
                                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
                              />
                            </div>
                            <div className="grecaptcha-error">
                            </div>
                            <textarea 
                              id="g-recaptcha-response-1" 
                              name="g-recaptcha-response" 
                              className="g-recaptcha-response" 
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 offset-lg-1">
                    {intro && intro.image && intro.image.url && (
                      <>
                        <img className="w-100 mt-5" alt="teaser" src={`${domain}${intro.image.url}`} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-10 offset-lg-1 pt-4 pb-5">
              <div className="container-fluid mt-lg-4 mt-5 pt-lg-5">
                <div className="row d-flex align-items-center">
                  <div className="col-12 col-lg-5">
                    {overviewSection && overviewSection.image && overviewSection.image.url && (
                      <>
                        <img className="w-100" alt="teaser" src={`${domain}${overviewSection.image.url}`} />
                      </>
                    )}
                    <img className="background-content-left d-lg-block" alt="background" src={BackgroundImg} />
                  </div>
                  <div className="col-12 col-lg-6">
                    <h2 className=" text-left">{overviewSection.title}</h2>
                    {overviewSection.description.map((item) => {
                      let listItems = [];
                      if (item.isList) {
                        listItems = item.description.split('- ');
                        listItems.shift();
                      }
                      return (
                        <div className="mt-4 mt-md-3 text-left">
                          <h4>{item.title}</h4>
                          {item.isList ? (
                            <div className="row">
                              <div className="col-12 col-lg-6">
                                <ul className="px-2 mt-2 d-none d-md-block">
                                  {listItems.slice(0, (listItems.length / 2)).map((listItem) => {
                                    return (
                                      <li className="pl-1">{listItem}</li>
                                    )
                                  })}
                                </ul>
                              </div>
                              <div className="col-12 col-lg-6">
                                <ul className="px-2 mt-2 d-none d-md-block">
                                  {listItems.slice((listItems.length / 2), listItems.length).map((listItem) => {
                                    return (
                                      <li className="pl-1">{listItem}</li>
                                    )
                                  })}
                                </ul>
                                <ul className="px-2 d-md-none">
                                  {listItems.map((listItem) => {
                                    return (
                                      <li className="pl-1">{listItem}</li>
                                    )
                                  })}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <p className="text-left">{item.description}</p>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="merging-content">
              <div className="col-12 col-lg-10 offset-lg-1 contact-boxes">
                <div className="row">
                  {comments.map((commentBox) => {
                    return (
                    <div className="col-12 col-lg-4 h-100 mt-4">
                      <div className="partial-contact-teaser bg-white h-100">
                        <div className="thumbnail">
                          {commentBox && commentBox.image && commentBox.image.url && (
                            <>
                              <img className="rounded-circle" src={`${domain}${commentBox.image.url}`} alt="thumbnail" />
                            </>
                          )}
                        </div>
                        <h4>{commentBox.name}</h4>
                        <p className="text-left">
                          {commentBox.comment}
                        </p>
                      </div>
                    </div>
                    )
                  })}
                </div>
              </div>
              <div className="container-fluid newsletter">
                <div className="row mx-lg-1 pr-0 newsletter-background">
                  <div className="col-lg-1 col-0 p-0">
                  </div>
                  <div className="col-lg-10 col-12">
                    <div className="container-fluid newsletter-container my-lg-3">
                      <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                          <h2 className="text-white" id="newsletter-anchor">
                            {subscription.title}
                          </h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 offset-md-3">
                          <form 
                            className="emailoctopus-form form-horizontal bound" 
                            action="https://emailoctopus.com/lists/b6e22eed-3f84-11eb-a3d0-06b4694bee2a/members/embedded/1.3s/add" 
                            method="post" 
                            data-message-success="Thanks for subscribing!" 
                            data-message-missing-email-address="Your email address is required." 
                            data-message-invalid-email-address="Your email address looks incorrect, please try again." 
                            data-message-bot-submission-error="This doesn't look like a human submission." 
                            data-message-consent-required="Please check the checkbox to indicate your consent." 
                            data-message-invalid-parameters-error="This form has missing or invalid fields." 
                            data-message-unknown-error="Sorry, an unknown error has occurred. Please try again later." 
                            data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6" 
                            data-widget-id="1"
                          >
                            <div className="emailoctopus-form-row form-group d-flex align-items-center justify-content-center mb-0 mt-3 text-left">
                              <input 
                                className="header-input input-lg bg-light border-0 form-control px-2 px-lg-3" 
                                id="field_0" 
                                name="field_0" 
                                type="email" 
                                placeholder="Bitte gib deine Mail Adresse ein" 
                                required={true}
                              />
                              <div className="emailoctopus-form-row-subscribe">
                                <input type="hidden" name="successRedirectUrl"/>
                                <button className="text-nowrap mb-0 ml-1 bg-danger submit-button py-2 px-3 border-0 header-input" type="submit">
                                  <img className="mr-md-2 text-white" src={submit} alt="subscribe"/>
                                  {language === 'en' ? (
                                    <span className="d-none d-md-inline">
                                      Sign up
                                    </span>
                                  ) : (
                                    <span className="d-none d-md-inline">
                                      Als Testuser eintragen
                                    </span>
                                  )}
                                </button>
                              </div>
                            </div>
                            <div className="g-recaptcha d-none" data-theme="light" data-size="invisible" data-type="image" data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6">
                              <div className="grecaptcha-badge" data-style="none">
                                <div className="grecaptcha-logo">
                                  <iframe 
                                    title="grecaptcha-logo"
                                    src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6&amp;co=aHR0cHM6Ly93d3cubWluYWJvdC5haTo0NDM.&amp;hl=de&amp;type=image&amp;v=-nejAZ5my6jV0Fbx9re8ChMK&amp;theme=light&amp;size=invisible&amp;cb=r4s9el81lfz6" 
                                    width="256" 
                                    height="60" 
                                    role="presentation" 
                                    name="a-vs0smw9uah8" 
                                    scrolling="no" 
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
                                  />
                                </div>
                                <div className="grecaptcha-error">
                                </div>
                                <textarea 
                                  id="g-recaptcha-response-1" 
                                  name="g-recaptcha-response" 
                                  className="g-recaptcha-response" 
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        {/* )} */}
      </div>
      <FooterLanding/>
    </Fragment>
  )
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPage($id: ID!) {
    strapi {
      landingpage(id: $id) {
        meta {
          title
          description
        }
        language
        intro {
          title
          description
          image {
            url
          }
        }
        comments {
          name
          image {
            url
          }
          comment
        }
        overviewSection {
          description {
            title
            description
            isList
          }
          title
          image {
            url
          }
        }
        subscription {
          title
        }
      }
    }
  }
`
