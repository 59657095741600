import React, { Fragment } from "react";

export const LanguageButton = ({language}) => {
  return (
    <Fragment>
       <a href={`/${language === 'en' ? 'de/' : 'en/'}`} className="text-nowrap mb-0 p-0 btn btn-prototyp submit-button p-2 px-3 header-input">
        <div className="d-flex align-items-center ">
          <svg className="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10C20 4.47581 15.5242 0 10 0ZM18.0645 10C18.0645 10.9274 17.9032 
            11.8145 17.621 12.621H16.7742C16.6129 12.621 16.4516 12.5403 16.3306 12.4194L15.0403 11.0887C14.879 10.9274 14.879 10.6048 15.0403 10.4435L15.5645 
            9.91935V9.59677C15.5645 9.47581 15.4839 9.35484 15.4032 9.27419L15.0403 8.87097C14.9597 8.79032 14.8387 8.75 14.7177 8.75H14.0726C13.8306 8.75 13.629 8.54839 
            13.629 8.30645C13.629 8.18548 13.6694 8.06452 13.75 7.98387L14.1129 7.58065C14.1935 7.5 14.3145 7.45968 14.4355 7.45968H15.7258C16.0081 7.45968 16.2097 7.25806 
            16.2097 7.01613V6.6129C16.2097 6.37097 16.0081 6.16935 15.7258 6.16935H14.2742C13.9113 6.16935 13.629 6.45161 13.629 6.81452V6.97581C13.629 7.25806 13.4274 7.5 
            13.1855 7.62097L11.8952 8.02419C11.7742 8.06452 11.6935 8.18548 11.6935 8.34677V8.42742C11.6935 8.58871 11.5323 8.75 11.371 8.75H10.7258C10.5242 8.75 10.4032 8.58871 
            10.4032 8.42742C10.4032 8.22581 10.2419 8.10484 10.0806 8.10484H9.95968C9.83871 8.10484 9.71774 8.18548 9.6371 8.26613L9.27419 9.03226C9.15323 9.23387 8.95161 9.39516 
            8.70968 9.39516H7.82258C7.45968 9.39516 7.17742 9.1129 7.17742 8.75V7.70161C7.17742 7.54032 7.21774 7.37903 7.33871 7.25806L8.14516 6.45161C8.34677 6.25 8.46774 6.00806 
            8.46774 5.76613C8.46774 5.60484 8.54839 5.48387 8.66935 5.44355L10.2823 4.91935C10.3629 4.87903 10.4032 4.83871 10.4435 4.79839L11.5323 3.70968C11.6129 3.62903 11.6935 
            3.50806 11.6935 3.3871C11.6935 3.14516 11.4516 2.94355 11.2097 2.94355H10.4032L9.75806 3.58871V3.91129C9.75806 4.07258 9.59677 4.23387 9.43548 4.23387H8.79032C8.58871 
            4.23387 8.46774 4.07258 8.46774 3.91129V3.10484C8.46774 2.98387 8.50806 2.90323 8.58871 2.82258L9.75806 1.97581C9.83871 1.97581 9.87903 1.93548 9.95968 1.93548C14.4355 
            1.93548 18.0645 5.56452 18.0645 10ZM5.24194 5.72581C5.24194 5.60484 5.28226 5.48387 5.3629 5.40323L6.37097 4.35484C6.45161 4.27419 6.57258 4.23387 6.69355 4.23387C6.97581 
            4.23387 7.17742 4.43548 7.17742 4.67742V5.32258C7.17742 5.44355 7.09677 5.56452 7.01613 5.64516L6.65323 6.04839C6.57258 6.12903 6.45161 6.16935 6.33065 6.16935H5.68548C5.44355 
            6.16935 5.24194 5.96774 5.24194 5.72581ZM10.4032 18.0645V17.7823C10.4032 17.4194 10.0806 17.1371 9.75806 17.1371H8.91129C8.50806 17.1371 7.8629 16.9355 7.5 16.6532L6.6129 
            15.9677C6.12903 15.6452 5.8871 15.0806 5.8871 14.5161V13.5484C5.8871 12.9032 6.20968 12.2984 6.77419 11.9758L8.50806 10.9274C8.79032 10.7661 9.1129 10.6855 9.43548 10.6855H10.6855C11.129 
            10.6855 11.5726 10.8468 11.8952 11.129L13.629 12.621H14.3548C14.7177 12.621 15.0403 12.7419 15.2823 12.9839L15.9677 13.7097C16.129 13.8306 16.2903 13.9113 16.4919 13.9113H17.0565C15.7258 
            16.2903 13.2661 17.9032 10.4032 18.0645Z" fill="#002D40"/>
          </svg>


          {language === 'en' ? (
            <>
              German
            </>
          ) : (
            <>
              Englisch
            </>
          )}
        </div>
      </a>
    </Fragment>
  )
}