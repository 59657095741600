import React, { Fragment, useContext } from "react"
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import twitter from '../../images/twitter-icon-blue.svg'
import { LanguageButton } from "../partials/buttons/language-button";



export const FooterLanding = ({ header, language }) => {
  const state = useContext(GlobalStateContext)

  return (
    <Fragment>
     <div className="footer-landing py-4">
      <div className="col-12 col-lg-10 offset-lg-1 contact-boxes d-none d-lg-block">
        <div className="row">
          <div className="col-12 col-md-3 d-flex flex-column justify-content-between h-100">
            <div>
              <LanguageButton language={language}/>
            </div>
            <div>
              <div className="py-3">
                <a className="mb-2 circle-light" href="https://twitter.com/minachatbot" target="_blank">
                  <img src={twitter} alt="twitter" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <h4>MinaBot beschäftigt sich außerdem mit</h4>
            <div className="row">
              <div className="col-6">
                <a href="/de/landing/pruefungsangst">
                  Prüfungsangst
                </a>
              </div>
              <div className="col-6">

              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <h4>Rechtliche Infos</h4>
            <div className="mb-1">
              <a href="/de/datenschutz">
                Datenschutzerklärung
              </a>
            </div>
            <div className="mb-1">
              <a href="/de/agb">
                AGB
              </a>
            </div>
            <div className="mb-1">
              <a href="/de/impressum">
                Impressum
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <small>
              2021 © Copyright MinaBot Software
            </small>
          </div>
        </div>
      </div>

      <div className="d-lg-none col-12">
        <div className="container-fluid border-bottom">
          <div className="row">
            <div className="col-12 mt-4">
              <h4>MinaBot beschäftigt sich außerdem mit</h4>
              <a href="/de/landing/pruefungsangst">
                Prüfungsangst
              </a>
            </div>
            <div className="col-12 mt-4">
              <h4>Rechtliche Infos</h4>
              <div className="mb-1">
                <a href="/de/datenschutz">
                  Datenschutzerklärung
                </a>
              </div>
              <div className="mb-1">
                <a href="/de/agb">
                  AGB
                </a>
              </div>
              <div className="mb-1">
                <a href="/de/impressum">
                  Impressum
                </a>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <LanguageButton language={language}/>
                <div className="py-3">
                  <a className="mb-2 circle-light" href="https://twitter.com/minachatbot" target="_blank">
                    <img src={twitter} alt="twitter" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center mt-2">
              <small>
                2021 © Copyright MinaBot Software
              </small>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  )
}
