import React, { Fragment } from "react";

export const PrototypeButton = ({children}) => {
  return (
    <Fragment>
      <a href="https://chats.landbot.io/v3/H-841298-YRUAU4XIPDNR1JSG/index.html" target="_blank" className="text-nowrap mb-0 p-0 btn btn-prototyp submit-button d-flex align-items-center justify-content-center px-3 header-input">
        <div className="d-flex align-items-center ">
          <svg className="mr-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.9375 1.09375C5.625 1.40625 5.65625 1.875 5.9375 2.1875L9.71875 5.75H0.75C0.3125 5.75 0 6.09375 0 6.5V7.5C0 7.9375 0.3125 8.25 0.75 8.25H9.71875L5.9375 
            11.8438C5.65625 12.1562 5.65625 12.625 5.9375 12.9375L6.625 13.625C6.9375 13.9062 7.40625 13.9062 7.6875 13.625L13.7812 7.53125C14.0625 7.25 14.0625 6.78125 13.7812 
            6.46875L7.6875 0.40625C7.40625 0.125 6.9375 0.125 6.625 0.40625L5.9375 1.09375Z" fill="#002D40"/>
          </svg>

          {children ? (
            <>
              {children}
            </>
          ) : (
            <>
              Mina Prototyp hier testen
            </>
          )}
        </div>
      </a>
    </Fragment>
  )
}