import React, { Fragment, useContext } from "react"
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import BackgroundSquare from '../../images/background-element-header.svg';
import logo from '../../images/logo.svg';
import badge from '../../images/badge-icon.svg';
import { PrototypeButton } from "../partials/buttons/prototype-button";


export const NavbarLanding = ({ header, language }) => {
  const state = useContext(GlobalStateContext)

  return (
    <Fragment>
      <nav className="navbar-landing">
        <div className="prototype-wrap d-none d-lg-flex">
          <PrototypeButton/>
        </div>
        <nav className="navbar-landing navbar justify-content-between py-lg-y py-2">
          <a className="navbar-brand" href={`/${language}`}> 
            <img className="logo" src={logo} alt="logo" />
          </a>
          <div className="prototype-wrap d-lg-none d-inline">
            <PrototypeButton>
              Mina Prototyp testen
            </PrototypeButton>
          </div>
          <div className="d-none d-lg-inline">
            {language === 'en' ? (
              <div className="text-left small text-white badges">
                <div className="d-flex align-items-center">
                  <img className="mr-2" src={badge} alt="logo" />
                  <span>Von Therapeuten & Software-Experten entwickelt</span>
                </div>
                <img className="background-img" alt="teaser" src={BackgroundSquare} />
              </div>
            ) : (
              <div className="text-left small text-white badges">
                <div className="d-flex align-items-center">
                  <img className="mr-2" src={badge} alt="logo" />
                  <span>Von Therapeuten & Software-Experten entwickelt</span>
                </div>
                <img className="background-img" alt="teaser" src={BackgroundSquare} />
              </div>
            )}
          </div>
        </nav>
      </nav>
    </Fragment>
  )
}
